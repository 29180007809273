/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';
@import "bootstrap-icons/font/bootstrap-icons.css";


.attribute-container {
  align-content: center;
}
